<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Alertes trajets",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "alertes  trajets",
          active: true,
        },
      ],
      ordersData: [
        {
          ordertrajet: "cotonou >> Parakou",
          user: "Nom & prénom",
          date: "28 Mar, 2020",
        },
      ],
      thenDay: "",
      id: "",
      totalRows: 0,
      totalRowsDisable: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      alerteData: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label: "Trajet" },
        { key: "depart", sortable: true, label: "Date départ" },
        { key: "siege", sortable: true, label: "Nombre de place départ" },
        { key: "user", label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "date", sortable: true, label: "Date" },
        { key: "departTime", sortable: true, label: "Heure de départ" },

        { key: "action", label: "Actions" },
      ],
      fieldsAlertesDes: [
        { key: "itinéraire", label: "Trajet" },
        { key: "depart", sortable: true, label: "Date départ" },
        { key: "siege", sortable: true, label: "Nombre de place départ" },
        { key: "user", label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "date", sortable: true, label: "Date" },
        { key: "departTime", sortable: true, label: "Heure de départ" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      startValue: 1,
      startValueOf: 1,
      endValue: 500,
      endValueOf: 100,
      totalValue: "0",
      totalValueOf: "0",
      userChargeLoader: false,
      newDataAlerte: [],
      newDataAlerteInactive: [],
      newStart: false,
      recherche:"",
      paarBon:500,
      filterInactif:""
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const alerteTrajets = await apiRequest(
        "GET",
        "admin/alertes",
        undefined,
        false
      );

      if (alerteTrajets && alerteTrajets.data) {
        console.log("alertes:", alerteTrajets.data);
        const alerteTrajetsTable = alerteTrajets.data.map((alert) => {
          return {
            id: alert.id,
            siege: alert.sieges,
            itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
            user: alert.alerteur.firstname + " " + alert.alerteur.lastname,
            tel:
              alert.alerteur.tel && alert.alerteur.tel != ""
                ? alert.alerteur.indicatifTel + alert.alerteur.tel
                : "",
            date: new Date(alert.dateAlerte).toLocaleString("fr-FR"),
            depart: new Date(alert.dateHeureDepart).toLocaleString("fr-FR").split(" ")[0],
            departTime:alert.heureSouhaite
          };
        });

        this.alerteData = alerteTrajetsTable;
        this.id = alert.id;

        this.totalRows = this.alerteData.length;
      }

      // Service pour afficher la liste des alertes désactivées

      const alerteTrajetsDes = await apiRequest(
        "GET",
        "admin/alertes-inactif",
        undefined,
        false
      );

      if (alerteTrajetsDes && alerteTrajetsDes.data) {
        const alerteTrajetsTableDes = alerteTrajetsDes.data.map((alert) => {
          return {
            id: alert.id,
            siege: alert.sieges,
            itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
            user: alert.alerteur.firstname + " " + alert.alerteur.lastname,
            tel:
              alert.alerteur.tel && alert.alerteur.tel != ""
                ? alert.alerteur.indicatifTel + alert.alerteur.tel
                : "",
            date: new Date(alert.dateAlerte).toLocaleString("fr-FR"),
            depart: new Date(alert.dateHeureDepart).toLocaleString("fr-FR").split(" ")[0],
            departTime:alert.heureSouhaite
          };
        });
        this.alerteDataDes = alerteTrajetsTableDes;
      }
      this.totalRowsDisable = this.alerteDataDes.length;
    },
    // eslint-disable-next-line no-unused-vars
    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/alertes?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const alerteTrajetsTable = data.data.map((alert) => {
          return {
            id: alert.id,
            siege: alert.sieges,
            itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
            user: alert.alerteur.firstname + " " + alert.alerteur.lastname,
            tel:
              alert.alerteur.tel && alert.alerteur.tel != ""
                ? alert.alerteur.indicatifTel + alert.alerteur.tel
                : "",
            date: new Date(alert.dateAlerte).toLocaleString("fr-FR"),
            depart: new Date(alert.dateHeureDepart).toLocaleString("fr-FR"),
          };
        });

        this.newDataAlerte = alerteTrajetsTable;
        this.id = alert.id;
        this.userChargeLoader= false,
        this.totalRows = this.alerteData.length;
      }
    },

    async inactif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/alertes-inactif?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const alerteTrajetsTable = data.data.map((alert) => {
          return {
            id: alert.id,
            siege: alert.sieges,
            itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
            user: alert.alerteur.firstname + " " + alert.alerteur.lastname,
            tel:
              alert.alerteur.tel && alert.alerteur.tel != ""
                ? alert.alerteur.indicatifTel + alert.alerteur.tel
                : "",
            date: new Date(alert.dateAlerte).toLocaleString("fr-FR"),
            depart: new Date(alert.dateHeureDepart).toLocaleString("fr-FR"),
          };
        });

        this.newDataAlerteInactive = alerteTrajetsTable;
        this.id = alert.id;
        this.userChargeLoader= false,
        this.totalRows = this.alerteData.length;
      }
    },
  

    async userSearch() {
 
 this.userChargeLoader = true;
 const dataSearch = await apiRequest(
   "POST",
   "admin/alertes/recherche",

   { mots: this.recherche },
   false
 );

 if (dataSearch && dataSearch.data) {

  const alerteTrajetsTable = dataSearch.data.map((alert) => {
          return {
            id: alert.id,
            siege: alert.sieges,
            itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
            user: alert.alerteur.firstname + " " + alert.alerteur.lastname,
            tel:
              alert.alerteur.tel && alert.alerteur.tel != ""
                ? alert.alerteur.indicatifTel + alert.alerteur.tel
                : "",
            date: new Date(alert.dateAlerte).toLocaleString("fr-FR"),
            depart: new Date(alert.dateHeureDepart).toLocaleString("fr-FR"),
          };
        });

        this.newDataAlerte = alerteTrajetsTable;
        this.id = alert.id;
        this.userChargeLoader= false,
        this.totalRows = this.alerteData.length;
}
    },








    formSubmit(e) {
      this.submitted = true;
      console.log(e);
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    goToDetail(row) {
      console.log("row:", row);
      //router.push(`/trajets/alertes/${row.id}`)
      //router.push('/user/user_info/'+row.id)
    },
    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
    },

    async desactiveAlerte() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/alerte-active",
        {
          id: this.rowCall,
          active: false,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.init();


        this.$toast.success("Opération effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="alerteData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
            <div class="card-title">
              <h5>
              <span class="text-green"> Nombre d'alertes de trajets </span> : {{ this.totalRows }} <div class="borderLeft" ></div>
               <span class="text-red" > Nombre d'alertes de trajets désactivées</span>  : {{ this.totalRowsDisable }}
              </h5>
            </div>
          <!--   <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
     <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == this.parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
            </div>
            
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Alertes actives</a>
                  </template>
                  <div class="row  d-flex justify-content-between mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length ">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol ">
                      <div class="input ">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="recherche"
                    @keyup.enter="userSearch"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="newDataAlerte.length!=0?newDataAlerte:alerteData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click="desactivity(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                      </template>
                      <template
                        v-slot:cell(tel)="row"
                        style="text-align: center"
                      >
                        <a
                          :href="'https://wa.me/' + row.value"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
              <div class="col">
              <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                      :rows="alerteData"
                    ></b-pagination>
                  </ul>
                </div> 
        <!--         <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
               
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == this.parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
              </div>
            </div>
                </b-tab>
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Alertes désactivées</a>
                  </template>

                  <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
               
              </div>
             <!--  <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
     
                    <li>
                      <span class="interval"> {{ startValueOf }}</span> -
                      <span class="interval">{{ endValueOf }}</span> sur
                      {{ totalRowsDisable}}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValueOf == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValueOf <= parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValueOf >= totalRowsDisable"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValueOf >= totalRowsDisable"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
            </div>
                  <div class="row d-flex justify-content-between mt-4">
                    <div class="">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input mr-2">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="filterInactif"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="newDataAlerteInactive.length != 0?newDataAlerteInactive :alerteDataDes"
                      :fields="fieldsAlertesDes"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filterInactif"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template
                        v-slot:cell(tel)="row"
                        style="text-align: center"
                      >
                        <a
                          :href="'https://wa.me/' + row.value"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="col">
         <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRowsDisable"
                      :per-page="perPage"
                      :aria-controls="trajet"
                      :rows="alerteDataDes"
                    ></b-pagination>
                  </ul>
                </div> 
               <!--  <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
        
                      <li>
                        <span class="interval"> {{ startValueOf }}</span> -
                        <span class="interval">{{ endValueOf }}</span> sur
                        {{ totalValueOf }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValueOf == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="endValueOf == parBon"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                          :disabled="endValueOf >= totalRowsDisable"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                          :disabled="endValueOf >= totalRowsDisable"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition> -->
              </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveAlerte"
          >Oui</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}
thead div{
  color:#fff;
}
.input {
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 100px; /* Need a specific value to work */
position: absolute;
display: flex;
justify-content: center;
width: 200px;
height: 35px;
bottom: 0;

margin-bottom: 25px;
margin-right: 15px;
flex: 60%;


box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
}

.nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}
.nav-tabs-custom .nav-item .nav-link.active a{
    color: white;
    font-weight: 600!important;
    vertical-align: middle;
    
}
.nav-tabs-custom .nav-item .nav-link.active {

    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid blue;
 
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}


.borderLeft{
  height: 15px;
  width: 2;
  border-right: 3px solid #252b3b;
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;
}



/** SYstem loading */
.paging_simple_numbers{
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  padding: 10px 30px;
}
ion-icon{
  cursor: pointer;
}
.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -22%;
  left: 0;
  right: 0;
  margin: 0 auto;
}



.paging_simple_numbers{
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  padding: 10px 30px;
}

.myBouton{
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
  
}


.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}


.text-green{
  color:#3AAA35;
}
</style>
